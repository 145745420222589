import React from 'react'

const PlayIcon = props => (
  <svg title="play icon" viewBox="0 0 76 76" {...props}>
    <defs>
      <style>{'.prefix__cls-1{fill:currentColor}'}</style>
    </defs>
    <g id="prefix__Group_2" data-name="Group 2">
      <g id="prefix__Group_1" data-name="Group 1">
        <path
          id="prefix__Path_1"
          data-name="Path 1"
          className="prefix__cls-1"
          d="M38 0a38 38 0 1 0 38 38A38.043 38.043 0 0 0 38 0zm0 72.833A34.833 34.833 0 1 1 72.833 38 34.873 34.873 0 0 1 38 72.833z"
        />
        <path
          id="prefix__Path_2"
          data-name="Path 2"
          className="prefix__cls-1"
          d="M216.607 163.833l-22.167-14.25a1.583 1.583 0 0 0-2.44 1.331v28.5a1.583 1.583 0 0 0 2.44 1.331l22.167-14.25a1.583 1.583 0 0 0 0-2.663zm-21.44 12.681v-22.7l17.655 11.349z"
          transform="translate(-163.5 -127.165)"
        />
      </g>
    </g>
  </svg>
)

export default PlayIcon
