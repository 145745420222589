import React from 'react'

const BookIcon = props => (
  <svg viewBox="0 0 82.179 82.179" title="Book" {...props}>
    <defs>
      <style>{'.prefix__cls-1{fill:currentColor}'}</style>
    </defs>
    <g id="prefix__Group_57" data-name="Group 57">
      <g id="prefix__Group_56" data-name="Group 56">
        <path
          id="prefix__Path_52"
          data-name="Path 52"
          className="prefix__cls-1"
          d="M290.279 203.737a1.371 1.371 0 0 0 1.844.6c.081-.041 8.143-4.136 12.119-5.392a1.37 1.37 0 0 0-.827-2.613c-4.188 1.324-12.2 5.392-12.535 5.565a1.37 1.37 0 0 0-.601 1.84z"
          transform="translate(-243.563 -164.765)"
        />
        <path
          id="prefix__Path_53"
          data-name="Path 53"
          className="prefix__cls-1"
          d="M291.5 102.086a1.367 1.367 0 0 0 .621-.149c.081-.041 8.143-4.136 12.119-5.392a1.371 1.371 0 0 0-.827-2.613c-4.188 1.324-12.2 5.392-12.535 5.565a1.369 1.369 0 0 0 .623 2.59z"
          transform="translate(-243.563 -78.801)"
        />
        <path
          id="prefix__Path_54"
          data-name="Path 54"
          className="prefix__cls-1"
          d="M80.811 20.545a1.369 1.369 0 0 0-1.37 1.37v53.416a4.114 4.114 0 0 1-4.109 4.109H42.461v-3.152a40.362 40.362 0 0 1 12.327-2.327 70.646 70.646 0 0 1 20.112 2.67 1.37 1.37 0 0 0 1.8-1.3V15.067a1.368 1.368 0 0 0-1.072-1.337s-1.074-.24-2.909-.577a1.37 1.37 0 0 0-.5 2.694c.7.129 1.286.242 1.738.333v57.33a77.35 77.35 0 0 0-19.175-2.289 43.029 43.029 0 0 0-13.643 2.624 32.086 32.086 0 0 0-12.38-2.624A92.3 92.3 0 0 0 8.22 73.568V16.127A90.153 90.153 0 0 1 28.764 13.7a29.7 29.7 0 0 1 10.957 2.257v53.9a1.369 1.369 0 0 0 2.079 1.169c.136-.081 13.706-8.186 25.751-12.2a1.37 1.37 0 0 0 .937-1.3V1.37A1.372 1.372 0 0 0 66.632.087a145.7 145.7 0 0 0-22.158 11.079 1.37 1.37 0 0 0 1.452 2.323A146.39 146.39 0 0 1 65.745 3.373v53.172a153.491 153.491 0 0 0-23.284 10.928V15.067a1.371 1.371 0 0 0-.757-1.226 31.715 31.715 0 0 0-12.939-2.883A88.893 88.893 0 0 0 6.473 13.75a1.369 1.369 0 0 0-.993 1.316v60.265A1.369 1.369 0 0 0 6.85 76.7a1.35 1.35 0 0 0 .375-.053 87.982 87.982 0 0 1 21.539-2.686 29.63 29.63 0 0 1 10.957 2.259v3.22H6.85a4.114 4.114 0 0 1-4.109-4.109V21.915a1.37 1.37 0 0 0-2.739 0v53.416a6.856 6.856 0 0 0 6.848 6.848h68.482a6.856 6.856 0 0 0 6.848-6.848V21.915a1.369 1.369 0 0 0-1.369-1.37z"
          transform="translate(-.002)"
        />
        <path
          id="prefix__Path_55"
          data-name="Path 55"
          className="prefix__cls-1"
          d="M290.279 152.537a1.371 1.371 0 0 0 1.844.6c.081-.041 8.143-4.136 12.119-5.392a1.37 1.37 0 0 0-.827-2.613c-4.188 1.324-12.2 5.392-12.535 5.565a1.37 1.37 0 0 0-.601 1.84z"
          transform="translate(-243.563 -121.782)"
        />
        <path
          id="prefix__Path_56"
          data-name="Path 56"
          className="prefix__cls-1"
          d="M290.279 254.937a1.371 1.371 0 0 0 1.844.6c.081-.041 8.143-4.136 12.119-5.392a1.37 1.37 0 0 0-.827-2.613c-4.188 1.325-12.2 5.392-12.535 5.565a1.37 1.37 0 0 0-.601 1.84z"
          transform="translate(-243.563 -207.747)"
        />
        <path
          id="prefix__Path_57"
          data-name="Path 57"
          className="prefix__cls-1"
          d="M104.821 154.4c-8.457-1.985-18.068.544-18.472.652a1.37 1.37 0 0 0 .353 2.693 1.393 1.393 0 0 0 .356-.047c.092-.026 9.345-2.46 17.138-.631a1.369 1.369 0 1 0 .624-2.667z"
          transform="translate(-71.637 -128.984)"
        />
        <path
          id="prefix__Path_58"
          data-name="Path 58"
          className="prefix__cls-1"
          d="M104.821 205.606c-8.457-1.983-18.068.544-18.472.652a1.37 1.37 0 0 0 .353 2.693 1.392 1.392 0 0 0 .356-.047c.092-.026 9.345-2.46 17.138-.631a1.369 1.369 0 1 0 .624-2.667z"
          transform="translate(-71.637 -171.969)"
        />
        <path
          id="prefix__Path_59"
          data-name="Path 59"
          className="prefix__cls-1"
          d="M290.279 306.137a1.371 1.371 0 0 0 1.844.6c.081-.041 8.143-4.136 12.119-5.392a1.37 1.37 0 0 0-.827-2.613c-4.188 1.324-12.2 5.392-12.535 5.565a1.37 1.37 0 0 0-.601 1.84z"
          transform="translate(-243.563 -250.729)"
        />
        <path
          id="prefix__Path_60"
          data-name="Path 60"
          className="prefix__cls-1"
          d="M104.821 256.8c-8.457-1.983-18.068.542-18.472.652a1.371 1.371 0 0 0 .353 2.694 1.387 1.387 0 0 0 .356-.048c.092-.026 9.345-2.46 17.138-.631a1.369 1.369 0 1 0 .624-2.667z"
          transform="translate(-71.637 -214.95)"
        />
        <path
          id="prefix__Path_61"
          data-name="Path 61"
          className="prefix__cls-1"
          d="M104.821 359.2c-8.457-1.985-18.068.544-18.472.652a1.371 1.371 0 0 0 .353 2.694 1.387 1.387 0 0 0 .356-.048c.092-.026 9.345-2.46 17.138-.631a1.369 1.369 0 1 0 .624-2.667z"
          transform="translate(-71.637 -300.912)"
        />
        <path
          id="prefix__Path_62"
          data-name="Path 62"
          className="prefix__cls-1"
          d="M104.821 308c-8.457-1.985-18.068.542-18.472.652a1.371 1.371 0 0 0 .353 2.694 1.387 1.387 0 0 0 .356-.048c.092-.026 9.345-2.46 17.138-.631a1.369 1.369 0 1 0 .624-2.667z"
          transform="translate(-71.637 -257.929)"
        />
      </g>
    </g>
  </svg>
)

export default BookIcon
