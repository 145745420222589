import React from 'react'
import moment from 'moment'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { cover } from 'polished'

import SEO from 'src/components/seo'
import {
  Box,
  Button,
  DisplayText,
  DonationsBlock,
  GivingCard,
  FlexCol,
  FlexGrid,
  H2,
  H3,
  Highlight,
  Text,
} from 'src/components'

import BookIcon from './_components/_bookIcon'
import ClassroomIcon from './_components/_classroomIcon'
import HandsIcon from './_components/_handsIcon'
import PlayIcon from './_components/_playIcon'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

const EventItem = ({
  linkTo,
  image,
  heading,
  body,
  date,
  button,
  color,
  ...props
}) => (
  <Box width={1} {...props}>
    <Box bg={'brand.' + color.toLowerCase()}>
      <FlexGrid>
        <FlexCol width={[1, null, 1 / 3]}>
          <Box
            as="a"
            href={linkTo}
            target="_blank"
            position="relative"
            display="block"
            pb="calc(2/3 * 100%)"
            bg="bg.alt"
            backgroundImage={image ? 'url(' + image + ')' : ''}
            backgroundSize="cover"
            backgroundPosition="center"
          />
        </FlexCol>
        <FlexCol width={[1, null, 2 / 3]}>
          <FlexCol height="100%" p={3}>
            <H3
              children={heading}
              as="a"
              href={linkTo}
              target="_blank"
              position="relative"
              display="block"
              mb="0.25em"
              style={{ textDecoration: 'none' }}
              color="text.reverse"
            />
            <Text children={body} mb="0.25em" color="text.reverse" />
            <Text children={date} mb="1em" color="rgba(255, 255, 255, 0.75)" />
            <Box mt="auto">
              <Button
                children={button}
                as="a"
                href={linkTo}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Box>
          </FlexCol>
        </FlexCol>
      </FlexGrid>
    </Box>
  </Box>
)

const IndexPage = () => (
  <>
    <StaticQuery
      query={graphql`
        query {
          home: prismicHomePage {
            dataString
            data {
              hero_image {
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
              }
              featured_video_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          events: allPrismicEvent {
            edges {
              node {
                prismicId
                dataString
              }
            }
          }
          products: allPrismicProduct {
            edges {
              node {
                uid
                prismicId
                data {
                  product_image {
                    fluid(maxWidth: 1200) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  product_color
                  product_name {
                    text
                  }
                  product_description {
                    text
                  }
                }
              }
            }
          }
          campaigns: allPrismicCampaign {
            edges {
              node {
                uid
                prismicId
                dataString
              }
            }
          }
        }
      `}
      render={data => {
        let page = JSON.parse(data.home.dataString)
        if (page.featured_products) {
          page.featured_products.forEach(featuredProduct => {
            data.products.edges.forEach(product => {
              if (
                featuredProduct.featured_product.id === product.node.prismicId
              ) {
                featuredProduct.featured_product.data = product.node.data
              }
            })
          })
        }
        data.events &&
          data.events.edges.forEach(event => {
            page.featured_events.forEach(featuredEvent => {
              if (event.node.prismicId === featuredEvent.featured_event.id) {
                featuredEvent.featured_event.data = JSON.parse(
                  event.node.dataString
                )
              }
            })
          })
        data.campaigns.edges &&
          data.campaigns.edges.forEach(campaign => {
            page.featured_campaigns.forEach(featuredCampaign => {
              if (
                campaign.node.prismicId ===
                featuredCampaign.featured_campaign.id
              ) {
                featuredCampaign.featured_campaign.data = JSON.parse(
                  campaign.node.dataString
                )
              }
            })
            page.featured_campaigns_opener.forEach(featuredCampaign => {
              if (
                campaign.node.prismicId ===
                featuredCampaign.featured_campaign.id
              ) {
                featuredCampaign.featured_campaign.data = JSON.parse(
                  campaign.node.dataString
                )
              }
            })
          })
        return (
          <>
            <SEO
              title={
                page.opengraph_title
                  ? RichText.asText(page.opengraph_title)
                  : ''
              }
              description={
                page.opengraph_description
                  ? RichText.asText(page.opengraph_description)
                  : ''
              }
              image={page.opengraph_image ? page.opengraph_image.url : ''}
            />
            <FlexGrid minHeight={['initial', 'calc(100vh - 56px - 112px)']}>
              <FlexCol flex={['initial', null, 1]} width={[1, null, 'initial']}>
                <Img
                  fluid={data.home.data.hero_image.fluid}
                  style={{ height: '100%', userSelect: 'none' }}
                />
              </FlexCol>
              <FlexCol
                justifyContent="center"
                alignItems="center"
                width={1}
                maxWidth={['100%', null, '75vh']}
                bg="bg.wash"
              >
                <FlexCol alignItems="center" mx={3} mb={4}>
                  <DisplayText
                    mb={[3, 4]}
                    textAlign="center"
                    style={{ transform: 'rotate(-7.5deg)' }}
                  >
                    <Highlight>{page.hero_header[0].text}</Highlight> <br />
                    <Highlight variant={1}>
                      {page.hero_header[1].text}
                    </Highlight>
                  </DisplayText>
                  <FlexCol alignItems="center" maxWidth={540}>
                    <Text
                      children={RichText.asText(page.hero_subheader)}
                      textAlign="center"
                    />
                    <Button
                      children={page.hero_button_text}
                      width={1}
                      maxWidth={192}
                      mt={3}
                      appearance="yellow"
                      as={Link}
                      to={page.hero_button_link}
                    />
                  </FlexCol>
                </FlexCol>
              </FlexCol>
            </FlexGrid>
            {console.log(page.featured_campaigns_opener)}
            {page.featured_campaigns_opener &&
              page.featured_campaigns_opener.length > 0 && (
                <Box px={[2, null, 3, 4]} py={[4, 5]}>
                  <Box maxWidth={1280} mx="auto">
                    {page.featured_campaigns_opener.map(campaign => {
                      if (campaign.featured_campaign.data) {
                        console.log(campaign.featured_campaign.data)
                        return (
                          <Box mb={3}>
                            <FlexGrid>
                              <FlexCol width={[1, 2 / 5]} bg="bg.alt">
                                <Box
                                  as={Link}
                                  to={
                                    '/campaign/' +
                                    campaign.featured_campaign.uid
                                  }
                                  target="_blank"
                                  position="relative"
                                  display="block"
                                  pb="calc(2/3 * 100%)"
                                  bg="bg.alt"
                                  backgroundImage={
                                    campaign.featured_campaign.data
                                      .campaign_image
                                      ? 'url(' +
                                        campaign.featured_campaign.data
                                          .campaign_image.url +
                                        ')'
                                      : ''
                                  }
                                  backgroundSize="cover"
                                  backgroundPosition="center"
                                />
                              </FlexCol>
                              <FlexCol
                                width={[1, 3 / 5]}
                                bg={
                                  'brand.' +
                                  campaign.featured_campaign.data.campaign_color.toLowerCase()
                                }
                              >
                                <Box py={[3, 4]} px={[3, 4]}>
                                  <H3
                                    children={RichText.asText(
                                      campaign.featured_campaign.data
                                        .campaign_title
                                    )}
                                    mb={2}
                                    color="text.reverse"
                                  />
                                  <Text
                                    children={RichText.asText(
                                      campaign.featured_campaign.data
                                        .campaign_description
                                    )}
                                    mb={3}
                                    color="text.reverse"
                                  />
                                  <Button
                                    children={`Learn more`}
                                    appearance="alt"
                                    as={Link}
                                    to={
                                      '/campaign/' +
                                      campaign.featured_campaign.uid
                                    }
                                  />
                                </Box>
                              </FlexCol>
                            </FlexGrid>
                          </Box>
                        )
                      } else {
                        return null
                      }
                    })}
                  </Box>
                </Box>
              )}
            <Box px={[2, null, 3, 4]} py={[4, 5]}>
              <Box maxWidth={1280} mx="auto">
                <Box mb={[4, 5]}>
                  <H2
                    children={RichText.asText(page.provides_title)}
                    mb={[3, 4]}
                    textAlign="center"
                  />
                  <FlexGrid gutterY={[3]}>
                    {page.provides_block.map((provides, index) => (
                      <Box alignItems="center" width={[1, 1 / 3]}>
                        <FlexCol alignItems="center" px={3}>
                          {provides.icon === 'Book' && (
                            <BookIcon
                              width={112}
                              height={112}
                              style={{ color: '#EAAA42' }}
                            />
                          )}
                          {provides.icon === 'Classroom' && (
                            <ClassroomIcon
                              width={112}
                              height={112}
                              style={{ color: '#EAAA42' }}
                            />
                          )}
                          {provides.icon === 'Hands' && (
                            <HandsIcon
                              width={112}
                              height={112}
                              style={{ color: '#EAAA42' }}
                            />
                          )}
                          {provides.icon === 'Play' && (
                            <PlayIcon
                              width={112}
                              height={112}
                              style={{ color: '#EAAA42' }}
                            />
                          )}
                          <Text
                            children={RichText.asText(provides.title)}
                            mt={[2, 3]}
                            textAlign="center"
                          />
                        </FlexCol>
                      </Box>
                    ))}
                  </FlexGrid>
                </Box>
                <Box bg="bg.wash">
                  <FlexGrid>
                    <FlexCol width={[1, 1 / 2]}>
                      <Box
                        width={1}
                        as="a"
                        href={page.featured_video_link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Box
                          position="relative"
                          pb="calc(4/5 * 100%)"
                          bg="bg.alt"
                        >
                          <Img
                            fluid={data.home.data.featured_video_image.fluid}
                            style={{ ...cover(), userSelect: 'none' }}
                          />
                          <Box
                            bg="rgba(0, 0, 0, 0.25)"
                            style={{ ...cover() }}
                          />
                          <PlayIcon
                            width={112}
                            height={112}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              color: '#fff',
                              transform: 'translate(-50%, -50%)',
                            }}
                          />
                        </Box>
                      </Box>
                    </FlexCol>
                    <FlexCol width={[1, 1 / 2]}>
                      <FlexCol
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        py={3}
                        px={4}
                      >
                        <Box mb="2em" textAlign="center">
                          {RichText.render(
                            page.feature_video_title,
                            linkResolver,
                            serializer
                          )}
                        </Box>
                        <Button
                          children={page.featured_video_button}
                          as={Link}
                          to={page.featured_video_button_link}
                          appearance="yellow"
                        />
                      </FlexCol>
                    </FlexCol>
                  </FlexGrid>
                </Box>
              </Box>
            </Box>
            <Box px={[2, null, 3, 4]} py={[4, 5]} bg="bg.wash">
              <DonationsBlock />
              {page.featured_products && page.featured_products.length > 0 && (
                <Box px={[2, null, 3, 4]} pt={[4, 5]}>
                  <Box maxWidth={1280} mx="auto">
                    {page.featured_products.map(product => {
                      if (product.featured_product.data) {
                        return (
                          <Box mb={3}>
                            <GivingCard
                              color={product.featured_product.data.product_color.toLowerCase()}
                              image={
                                product.featured_product.data.product_image
                                  ? product.featured_product.data.product_image
                                    ? product.featured_product.data
                                        .product_image.fluid
                                    : undefined
                                  : undefined
                              }
                              title={
                                product.featured_product.data.product_name
                                  ? product.featured_product.data.product_name
                                      .text
                                  : ''
                              }
                              description={
                                product.featured_product.data
                                  .product_description
                                  ? product.featured_product.data
                                      .product_description.text
                                  : ''
                              }
                              link={'/product/' + product.featured_product.uid}
                            />
                          </Box>
                        )
                      } else {
                        return null
                      }
                    })}
                  </Box>
                </Box>
              )}
              <Box
                maxWidth={1280}
                mx="auto"
                my={[4, 5]}
                height="1px"
                bg="bg.alt"
              />
              {page.featured_campaigns.length > 0 && (
                <>
                  <Box maxWidth={1280} mx="auto">
                    <Box>
                      <H2
                        children={`Other Ways to Give`}
                        my={[4, 5]}
                        textAlign="center"
                      />
                      {page.featured_campaigns.map((campaign, index) => (
                        <Box mb={3}>
                          <FlexGrid>
                            <FlexCol width={[1, 2 / 5]} bg="bg.alt">
                              <Box
                                as={Link}
                                to={
                                  '/campaign/' + campaign.featured_campaign.uid
                                }
                                target="_blank"
                                position="relative"
                                display="block"
                                pb="calc(2/3 * 100%)"
                                bg="bg.alt"
                                backgroundImage={
                                  campaign.featured_campaign.data.campaign_image
                                    ? 'url(' +
                                      campaign.featured_campaign.data
                                        .campaign_image.url +
                                      ')'
                                    : ''
                                }
                                backgroundSize="cover"
                                backgroundPosition="center"
                              />
                            </FlexCol>
                            <FlexCol
                              width={[1, 3 / 5]}
                              bg={
                                'brand.' +
                                campaign.featured_campaign.data.campaign_color.toLowerCase()
                              }
                            >
                              <Box py={[3, 4]} px={[3, 4]}>
                                <H3
                                  children={RichText.asText(
                                    campaign.featured_campaign.data
                                      .campaign_title
                                  )}
                                  mb={2}
                                  color="text.reverse"
                                />
                                <Text
                                  children={RichText.asText(
                                    campaign.featured_campaign.data
                                      .campaign_description
                                  )}
                                  mb={3}
                                  color="text.reverse"
                                />
                                <Button
                                  children={`Learn more`}
                                  appearance="alt"
                                  as={Link}
                                  to={
                                    '/campaign/' +
                                    campaign.featured_campaign.uid
                                  }
                                />
                              </Box>
                            </FlexCol>
                          </FlexGrid>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            {page.featured_events.length > 0 && (
              <Box px={[2, null, 3, 4]} py={[4, 5]}>
                <Box maxWidth={1280} mx="auto">
                  <FlexGrid gutterY={[3]}>
                    {page.featured_events.map((featuredEvent, index) => (
                      <EventItem
                        image={
                          featuredEvent.featured_event.data.event_image.url
                        }
                        heading={
                          featuredEvent.featured_event.data.event_title[0].text
                        }
                        body={
                          featuredEvent.featured_event.data.event_description
                        }
                        date={moment(
                          featuredEvent.featured_event.data.event_date_time
                        ).format('h:mm A MMMM Do, YYYY')}
                        button={
                          featuredEvent.featured_event.data.event_button_text
                        }
                        linkTo={featuredEvent.featured_event.data.event_link}
                        key={featuredEvent.featured_event.id}
                        color={featuredEvent.featured_event.data.event_color}
                      />
                    ))}
                  </FlexGrid>
                </Box>
              </Box>
            )}
          </>
        )
      }}
    />
  </>
)

export default IndexPage
