import React from 'react'

const HandsIcon = props => (
  <svg viewBox="0 0 470 470" title="Hands raised" {...props}>
    <path
      d="M235 120.918a7.5 7.5 0 0 0 7.5-7.5V7.5c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v105.918c0 4.143 3.357 7.5 7.5 7.5zM265.04 120.918a7.5 7.5 0 0 0 7.5-7.5V7.5c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v105.918c0 4.143 3.357 7.5 7.5 7.5z"
      fill="currentColor"
    />
    <path
      d="M212.46 137.85V29.288c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v115.503a7.5 7.5 0 0 0 7.5 7.5c19.416 0 35.213 15.798 35.213 35.216 0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5c0-25.141-18.57-46.026-42.713-49.657zM295.08 32.682a7.5 7.5 0 0 0-7.5 7.5v125.65c0 18.713-10.075 36.167-26.293 45.549a7.5 7.5 0 0 0 3.763 13.994 7.48 7.48 0 0 0 3.749-1.009 66.094 66.094 0 0 0 1.781-1.071V462.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V210.611c10.769-12.201 17-28.098 17-44.779V40.182a7.5 7.5 0 0 0-7.5-7.5zM208.713 211.381c-16.218-9.382-26.293-26.835-26.293-45.549V84.028c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v81.804c0 22.575 11.405 43.721 30.037 56.2V462.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V217.874a7.502 7.502 0 0 0-3.744-6.493zM84.799 243.452a7.5 7.5 0 0 0 7.5-7.5V130.034c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v105.918a7.5 7.5 0 0 0 7.5 7.5zM114.839 243.452a7.5 7.5 0 0 0 7.5-7.5V130.034c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v105.918a7.5 7.5 0 0 0 7.5 7.5z"
      fill="currentColor"
    />
    <path
      d="M62.259 260.383V151.822c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v115.502a7.5 7.5 0 0 0 7.5 7.5c19.416 0 35.213 15.798 35.213 35.216 0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5c0-25.14-18.571-46.025-42.713-49.657zM144.879 155.216a7.5 7.5 0 0 0-7.5 7.5v125.65c0 18.713-10.075 36.167-26.293 45.549a7.5 7.5 0 0 0 3.763 13.994 7.471 7.471 0 0 0 3.749-1.01 66.094 66.094 0 0 0 1.781-1.071V462.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V333.144c10.769-12.2 17-28.098 17-44.778v-125.65a7.5 7.5 0 0 0-7.5-7.5zM58.512 333.916c-16.218-9.383-26.293-26.836-26.293-45.549v-81.804c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v81.804c0 22.574 11.405 43.72 30.037 56.199V462.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V340.407a7.498 7.498 0 0 0-3.744-6.491zM385.201 183.452a7.5 7.5 0 0 0 7.5-7.5V70.034c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v105.918a7.5 7.5 0 0 0 7.5 7.5zM355.161 62.534a7.5 7.5 0 0 0-7.5 7.5v105.918c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V70.034a7.5 7.5 0 0 0-7.5-7.5z"
      fill="currentColor"
    />
    <path
      d="M422.741 207.324V91.822c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v108.562c-24.143 3.631-42.713 24.517-42.713 49.657 0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5c0-19.418 15.797-35.216 35.213-35.216a7.501 7.501 0 0 0 7.5-7.501zM358.914 273.916c-16.218-9.383-26.293-26.836-26.293-45.549v-125.65c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v125.65c0 16.68 6.231 32.578 17 44.778V462.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V285.828c.588.365 1.18.723 1.781 1.071a7.498 7.498 0 0 0 10.248-2.736 7.499 7.499 0 0 0-2.736-10.247zM445.281 139.062a7.5 7.5 0 0 0-7.5 7.5v81.804c0 18.713-10.075 36.167-26.293 45.549a7.499 7.499 0 0 0-3.744 6.492V462.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V284.565c18.632-12.479 30.037-33.625 30.037-56.199v-81.804a7.5 7.5 0 0 0-7.5-7.5z"
      fill="currentColor"
    />
  </svg>
)

export default HandsIcon
